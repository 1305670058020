import React from 'react';
import PropTypes from "prop-types";
import {Trans} from "gatsby-plugin-react-i18next";

const ContactCards = (props) => {
    return (
        <>
            <div className={'contact-card-container show'}>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>product_support</Trans></h4>
                        <p><Trans>need_assistance_with</Trans></p>
                        <ul>
                            <li><Trans>warranty_support</Trans></li>
                            <li><Trans>troubleshooting</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href="/support/#contactInfo" className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>
                </div>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>purchase_locks</Trans></h4>
                        <p><Trans>interested_purchasing_locks</Trans></p>
                        <ul>
                            <li><Trans>product_selection</Trans></li>
                            <li><Trans>product_information</Trans></li>
                            <li><Trans>product_pricing</Trans></li>
                            <li><Trans>product_upgrades</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href="/contact/purchase-locks/" className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>
                </div>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>other_questions</Trans></h4>
                        <p><Trans>interested_in</Trans></p>
                        <ul>
                            <li><Trans>media_inquiry</Trans></li>
                            <li><Trans>accounting_inquiry</Trans></li>
                            <li><Trans>other_inquiry</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href={"mailto:marketing@digilock.com"} className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ContactCards;