import React, {useState, useEffect} from "react";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import ContactCards from '../../components/contact/contact-cards';
import {StaticImage} from "gatsby-plugin-image";

const IndexPage = () => {
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo
            title={t('contact_title')}
            description={t('des_contact')}
        />
        <div className="contact">
          <section className="hero">
            <div className="container">
              <h1 dangerouslySetInnerHTML={{__html:t('how_can_we_help')}} />
            </div>
          </section>
          <section className="contact-info">
            <div className="container">
              <div className="sales-form">
                <ContactCards />
              </div>
            </div>
          </section>
          <section className="global-offices">
            <div className="container offices">
              <h2>
                <Trans>global_offices</Trans>
              </h2>
              <div>
                <Link to={'/about/offices/#gotoAmericas'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-americas.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Americas, San Francisco"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Americas</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoEurope'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-europe-netherlands.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Netherlands"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Europe, The Netherlands</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoAsia'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-asia.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Asia"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Asia, Hong Kong</Trans>
                    </figcaption>
                  </figure>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
